@if (visible) {
  <div class="notification is-warning is-py-3 is-px-4" role="alert">
    <div class="columns is-mobile">
      <div class="column is-narrow is-hidden-mobile">
        <fa-icon icon="exclamation-triangle" size="3x"></fa-icon>
      </div>
      <div class="column">
        <p>
          <fa-icon icon="exclamation-triangle" class="is-pr-2 is-hidden-tablet"></fa-icon>
          <b>{{ date | date }}:</b>
          <span class="is-pl-1">
            We have released the aggregated datasets for research and testing only. The datasets contain some known
            errors which we are working to fix - please email us at
          </span>
          <a class="is-pl-1" [href]="'mailto:' + contactUsEmail">{{ contactUsEmail }}</a>
          <span>
            for more information. They are also of varying levels of quality and completeness, and you can use our data
            quality scoring system to help understand this.
          </span>
        </p>
      </div>
      <div class="column is-narrow">
        <a (click)="hide()">
          <fa-icon icon="times" size="2x"></fa-icon>
        </a>
      </div>
    </div>
  </div>
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { UsersService, headerToken } from './users/users.service';

@Injectable()
export class UsersTokenInterceptor implements HttpInterceptor {
  private usersService = inject(UsersService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedReq =
      !this.usersService.token || request.method === 'JSONP'
        ? request
        : request.clone({
            headers: request.headers.set(headerToken, this.usersService.token)
          });
    return next.handle(modifiedReq);
  }
}

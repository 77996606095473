import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { configureScope, captureException } from '@sentry/angular-ivy';
import { File, isAdmin, isReviewer, isDeveloper, User } from '@hestia-earth/api';
import { NodeType } from '@hestia-earth/schema';
import { HeAuthService, HeUsersService, handleAPIError, filterParams } from '@hestia-earth/ui-components';

import { environment } from '../../environments/environment';

export const passwordMinLength = 6;
export const passwordPattern = `^.{${passwordMinLength},}$`;

export class ConfirmPasswordValidator {
  static match(control: AbstractControl) {
    const password = control.get('password').value;
    const confirm = control.get('passwordConfirm').value;
    return password !== confirm ? { matchPassword: true } : null;
  }
}

const path = `${environment.api.baseUrl}/users`;
const orcidApiUrl = 'https://pub.orcid.org/v2.1';
const profileStorageKey = 'he_p';
const tokenStorageKey = 'he_t';
const tokenDateStorageKey = 'he_ptd';

export const headerToken = 'X-ACCESS-TOKEN';

export const generatePassword = () => Math.random().toString(36).slice(-8);

const handleError = (error: Error) => {
  if (
    error?.message?.includes('users index: email') ||
    error?.message?.includes('users Failed _id or unique key constraint')
  ) {
    throw new Error('users-email-already-taken');
  }
  if (error?.message?.includes('users index:')) {
    throw new Error('users-auth-already-taken');
  }
  throw error;
};

export type sortByOrder = 'ascending' | 'descending';

export type searchByFields = 'firstName' | 'lastName' | 'email' | 'orcid' | 'primaryInstitution' | 'scopusID' | 'role';

export type sortByFields = 'email' | 'firstName' | 'lastName' | 'validFilesCount' | 'createdAt';

interface IUserSearchParams {
  search?: string;
  searchIn?: searchByFields[];
  offset?: number;
  limit?: number;
  sortOrder?: sortByOrder;
  sortBy?: sortByFields;
}

export class UserSearchResult {
  count: number;
  results: User[];
}

export class UserExtended extends User {
  public files: Partial<File>[] = [];
  public apiCalls: number;
}

export interface ORCIDWork {
  path: string;
  'created-date': { value: number };
  'last-modified-date': { value: number };
  title: {
    title: { value: string };
  };
  'external-ids': {
    'external-id': {
      'external-id-type': 'doi' | 'issn';
      'external-id-value': string;
      'external-id-url': { value: string };
    }[];
  };
  type: 'JOURNAL_ARTICLE';
  'publication-date': {
    year: { value: string };
    month: { value: string };
    day: { value: string };
  };
}

interface ORCIDWorks {
  'work-summary': ORCIDWork[];
}

export interface IUserNodesCount {
  public: { [type in NodeType]: number };
  private: { [type in NodeType]: number };
}

@Injectable({
  providedIn: 'root'
})
export class UsersService extends HeUsersService {
  private http = inject(HttpClient);
  private router = inject(Router);
  private localStorage = inject(LocalStorageService);
  private authService = inject(HeAuthService);

  private _user = new ReplaySubject<User>(1);
  private _token = '';

  public get token() {
    return this._token;
  }

  /**
   * Redirect after login.
   */
  public redirectUrl: string;

  constructor() {
    super();
    this.getCurrentUser();
  }

  private get shouldReloadUser() {
    // TODO: hotfix to fix double instance of Service which causes 502 errors
    const lastDate = this.tokenDate;
    return !lastDate || (new Date().getTime() - lastDate.getTime()) / 1000 > 60;
  }

  public async getCurrentUser() {
    try {
      this.updateToken(this.localStorage.retrieve(tokenStorageKey));
      this._user.next(this.localStorage.retrieve(profileStorageKey));
      // fetch user to make sure data is up to date
      this.shouldReloadUser && (await this.reloadUser());
    } catch (_err) {
      console.error(_err);
      // if we are using a wrong token
      if (this._token) {
        return await this.signOut();
      }
    }
    return true;
  }

  private get tokenDate() {
    const date = this.localStorage.retrieve(tokenDateStorageKey);
    return date ? new Date(date) : null;
  }

  private async reloadUser() {
    const user = await this.get();
    return user ? this.updateCurrentUser(user) : null;
  }

  private async get() {
    try {
      return this._token
        ? await this.http
            .get<User>(`${path}/me`, {
              headers: this.headers
            })
            .toPromise()
            .catch(handleAPIError)
        : null;
    } catch (err) {
      if (err.message === 'Unauthorized') {
        await this.signOut();
      } else {
        throw err;
      }
    }
  }

  public updateCurrentUser(user: User) {
    const { token, ...profile } = user;
    this._user.next(profile);
    this.localStorage.store(profileStorageKey, profile);
    this.updateToken(token);
    configureScope(scope => {
      scope.setUser({
        id: user.actorId,
        username: user.actorId
      });
    });
  }

  public updateToken(token: string) {
    this._token = token;
    this.authService.token = token;
    this.localStorage.store(tokenStorageKey, token);
    this.localStorage.store(tokenDateStorageKey, new Date().toJSON());
  }

  public get user$() {
    return this._user.asObservable();
  }

  public get isLoggedIn$() {
    return this._user.asObservable().pipe(map(user => !!user));
  }

  public get isAdmin$() {
    return this.user$.pipe(map(user => !!user && isAdmin(user)));
  }

  public get isReviewer$() {
    return this.user$.pipe(map(user => !!user && isReviewer(user)));
  }

  public get isDeveloper$() {
    return this.user$.pipe(map(user => !!user && isDeveloper(user)));
  }

  public get loggedInUser() {
    return this._user.pipe(take(1)).toPromise();
  }

  public get actor() {
    return this._user
      .pipe(
        map(user => ({ '@type': NodeType.Actor, '@id': user.actorId })),
        take(1)
      )
      .toPromise();
  }

  public get headers() {
    return this._token ? { headerToken: this._token } : {};
  }

  public create(data: Partial<User>) {
    return this.http.post<User>(path, data).toPromise().catch(handleAPIError).catch(handleError);
  }

  public delete(user?: Partial<User>) {
    return this.http.delete<void>(`${path}/${user?.email || 'me'}`).toPromise();
  }

  public async signIn(user: Partial<User>) {
    const res = await this.http.post<User>(`${path}/signin`, user).toPromise().catch(handleAPIError);
    try {
      this.updateCurrentUser(res);
    } catch (err) {
      captureException(err);
    }
  }

  public signOut() {
    this._user.next(null);
    this._token = null;
    this.localStorage.clear(profileStorageKey);
    this.localStorage.clear(tokenStorageKey);
    this.localStorage.clear(tokenDateStorageKey);
    return this.router.navigateByUrl('/');
  }

  public resendConfirm(user: Partial<User>) {
    return this.http.post<User>(`${path}/confirm/send`, user).toPromise().catch(handleAPIError);
  }

  public getUser$(email: string) {
    return this.http.get<UserExtended>(`${path}/${email}`);
  }

  public getUser(email: string) {
    return this.getUser$(email).toPromise().catch(handleAPIError).catch(handleError);
  }

  public update(data: Partial<User>) {
    return this.http
      .put<User>(`${path}/me`, data)
      .toPromise()
      .then(() => this.reloadUser())
      .catch(handleAPIError)
      .catch(handleError);
  }

  public updateUser(
    { email }: Partial<User>,
    { role, maxUploads, maxApiCalls, canCommitHestiaData, permissions }: Partial<User>
  ) {
    return this.http
      .put<User>(`${path}/${email}`, {
        role,
        maxUploads,
        maxApiCalls,
        canCommitHestiaData,
        permissions
      })
      .toPromise()
      .then(() => this.reloadUser())
      .catch(handleAPIError)
      .catch(handleError);
  }

  public updatePassword(user: Partial<User>, newPassword: string) {
    const data = { ...user, newPassword };
    return this.http.put<void>(`${path}/password`, data).toPromise().catch(handleAPIError);
  }

  public resetPassword(user: Partial<User>) {
    return this.http.post<User>(`${path}/password/reset`, user).toPromise().catch(handleAPIError);
  }

  public list<T extends UserSearchResult>(params?: IUserSearchParams) {
    return this.http
      .get<T>(path, { params: filterParams(params) })
      .toPromise()
      .catch(() => ({ count: 0, results: [] }));
  }

  public nodesCount(user?: Partial<User>) {
    return this.http.get<IUserNodesCount>(`${path}/${user?.email || 'me'}/nodes-count`).toPromise();
  }

  public async orcidWorks() {
    const defaultValue = { group: [] as ORCIDWorks[] };
    const { orcid } = await this.loggedInUser;
    const { group } = orcid
      ? await this.http
          .get<{ group: ORCIDWorks[] }>(`${orcidApiUrl}/${orcid}/works`, {
            headers: {
              Accept: 'application/json'
            }
          })
          .toPromise()
          .catch(() => defaultValue)
      : defaultValue;
    return group.map(val => val['work-summary'][0]);
  }
}

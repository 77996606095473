import {
  APP_ID,
  APP_INITIALIZER,
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  SecurityContext
} from '@angular/core';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientJsonpModule,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TraceService } from '@sentry/angular-ivy';
import {
  HE_API_BASE_URL,
  HE_CALCULATIONS_BASE_URL,
  HE_MAP_LOADED,
  HE_ORCHESTRATOR_BASE_URL,
  HeGlossaryService,
  HeSchemaService,
  HeSvgIconsModule,
  HeUsersService,
  loadMapApi
} from '@hestia-earth/ui-components';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { MARKED_OPTIONS, MarkedOptions, MarkedRenderer, provideMarkdown } from 'ngx-markdown';

import { environment } from './environments/environment';
import { SentryErrorHandler } from './sentry';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app-routing.module';
import { GlossaryService } from './app/glossary/glossary.service';
import { SchemaService } from './app/schema/schema.service';
import { UsersService } from './app/users/users.service';
import { ServiceUnavailableInterceptor } from './app/service-unavailable.interceptor';
import { ReadOnlyInterceptor } from './app/read-only.interceptor';
import { UsersTokenInterceptor } from './app/users-token.interceptor';

// TODO: remove this after all icons have been included per component
import { FontawesomeModule } from './app/fontawesome/fontawesome.module';
import { CHARTS_LOADED, loadCharts } from './app/shared/charts-loader';

import { DownloadApiInterceptor } from './app/download-shelf-dialog/data-access/services/download-api.interceptor';

// function that returns `MarkedOptions` with renderer override
export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.paragraph = (text: string) =>
    text
      .replace(/\\n/g, '<br>')
      .replace(/&quot;/g, '')
      .trim();

  return {
    renderer: renderer,
    gfm: true,
    breaks: true,
    pedantic: true
  };
}

if (environment.production) {
  enableProdMode();
}

const bootstrap = () =>
  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        BrowserModule,
        HttpClientJsonpModule,
        FormsModule,
        ReactiveFormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
        NgxWebstorageModule.forRoot({
          prefix: 'he_ws',
          separator: '-'
        }),
        HeSvgIconsModule,
        AppRoutingModule,
        FontawesomeModule
      ),
      provideMarkdown({
        loader: HttpClient,
        sanitize: SecurityContext.NONE,
        markedOptions: {
          provide: MARKED_OPTIONS,
          useFactory: markedOptionsFactory
        }
      }),
      { provide: APP_ID, useValue: 'serverApp' },
      { provide: HTTP_INTERCEPTORS, useClass: UsersTokenInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ReadOnlyInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ServiceUnavailableInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: DownloadApiInterceptor, multi: true },
      { provide: ErrorHandler, useClass: SentryErrorHandler },
      { provide: TraceService, deps: [Router] },
      { provide: APP_INITIALIZER, useFactory: () => () => null, deps: [TraceService], multi: true },
      { provide: HE_API_BASE_URL, useValue: environment.api.baseUrl },
      { provide: HE_CALCULATIONS_BASE_URL, useValue: environment.api.baseUrl },
      { provide: HE_ORCHESTRATOR_BASE_URL, useValue: environment.orchestratorConfigUrl },
      { provide: HE_MAP_LOADED, useFactory: loadMapApi(environment.mapsApiKey), deps: [HttpClient] },
      { provide: CHARTS_LOADED, useFactory: loadCharts(environment.mapsApiKey) },
      { provide: HeUsersService, useClass: UsersService },
      { provide: HeSchemaService, useClass: SchemaService },
      { provide: HeGlossaryService, useClass: GlossaryService },
      provideHttpClient(withInterceptorsFromDi())
    ]
  }).catch(err => console.error(err));

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

import { IsActiveMatchOptions } from '@angular/router';
import { SCHEMA_VERSION, SchemaType } from '@hestia-earth/schema';
import { INavigationMenuLink, baseUrl } from '@hestia-earth/ui-components';
import { keyToLabel } from '@hestia-earth/utils';

import { environment } from '../../environments/environment';

export const enableNoVersion = environment.name === 'Dev';
export const latestVersion = SCHEMA_VERSION;
export const maxVersionsSelect = 10;
export const noVersion = 'WIP (not deployed yet)';

const schemaBaseUrl = (version?: string) =>
  [baseUrl(), 'schema-data', version || (enableNoVersion ? '' : latestVersion)].filter(Boolean).join('/');

export const versionsUrl = (version?: string) => `${schemaBaseUrl(version)}/versions.txt`;

export const filterVersions = (versions: string[], currentVersion?: string) => {
  const index = currentVersion ? versions.indexOf(currentVersion) : 0;
  const previousVersions = versions.slice(index || 0, index + maxVersionsSelect);
  // get the previous 5 versions, and if not latest, show latest first
  return [
    enableNoVersion ? noVersion : null,
    previousVersions.includes(latestVersion) ? null : latestVersion,
    ...previousVersions
  ].filter(Boolean);
};

export const exampleUrl = (example: string, version?: string) => `${schemaBaseUrl(version)}/examples/${example}`;

const activeMatchOptions: IsActiveMatchOptions = {
  matrixParams: 'ignored',
  queryParams: 'ignored',
  fragment: 'ignored',
  paths: 'exact'
};

export const schemaMenuLinks = (urlPrefix: string): INavigationMenuLink[] => [
  {
    title: 'About the Schema',
    icon: 'far-circle-info',
    url: '/schema/about',
    activeMatchOptions
  },
  {
    title: 'Schema',
    icon: 'schema',
    expanded: true,
    links: [
      {
        title: SchemaType.Cycle,
        url: [urlPrefix, SchemaType.Cycle].join('/'),
        links: [
          {
            title: SchemaType.Completeness,
            url: [urlPrefix, SchemaType.Completeness].join('/')
          },
          {
            title: SchemaType.Practice,
            url: [urlPrefix, SchemaType.Practice].join('/')
          },
          {
            title: SchemaType.Animal,
            url: [urlPrefix, SchemaType.Animal].join('/')
          },
          {
            title: SchemaType.Input,
            url: [urlPrefix, SchemaType.Input].join('/')
          },
          {
            title: SchemaType.Product,
            url: [urlPrefix, SchemaType.Product].join('/')
          },
          {
            title: SchemaType.Transformation,
            url: [urlPrefix, SchemaType.Transformation].join('/')
          },
          {
            title: SchemaType.Emission,
            url: [urlPrefix, SchemaType.Emission].join('/')
          }
        ]
      },
      {
        title: SchemaType.Site,
        url: [urlPrefix, SchemaType.Site].join('/'),
        links: [
          {
            title: SchemaType.Management,
            url: [urlPrefix, SchemaType.Management].join('/')
          },
          {
            title: SchemaType.Measurement,
            url: [urlPrefix, SchemaType.Measurement].join('/')
          },
          {
            title: SchemaType.Infrastructure,
            url: [urlPrefix, SchemaType.Infrastructure].join('/')
          }
        ]
      },
      {
        title: SchemaType.Organisation,
        url: [urlPrefix, SchemaType.Organisation].join('/'),
        links: [
          {
            title: SchemaType.Infrastructure,
            url: [urlPrefix, SchemaType.Infrastructure].join('/')
          }
        ]
      },
      {
        title: keyToLabel(SchemaType.ImpactAssessment),
        url: [urlPrefix, SchemaType.ImpactAssessment].join('/'),
        links: [{ title: SchemaType.Indicator, url: [urlPrefix, SchemaType.Indicator].join('/') }]
      },
      {
        title: SchemaType.Source,
        url: [urlPrefix, SchemaType.Source].join('/'),
        links: [{ title: SchemaType.Bibliography, url: [urlPrefix, SchemaType.Bibliography].join('/') }]
      },
      { title: SchemaType.Actor, url: [urlPrefix, SchemaType.Actor].join('/') },
      { title: SchemaType.Term, url: [urlPrefix, SchemaType.Term].join('/') },
      { title: SchemaType.Property, url: [urlPrefix, SchemaType.Property].join('/') },
      { title: SchemaType.Transport, url: [urlPrefix, SchemaType.Transport].join('/') }
    ]
  }
];

<nav
  class="navbar is-spaced has-shadow"
  role="navigation"
  aria-label="main navigation"
  [class.is-fixed-top]="!isMobile"
  [class.is-active]="menuActive || userMenuActive || searchMenuActive"
  [class.is-transparent-mobile]="navbarService.transparent$ | async"
  [class.is-dark-mobile]="navbarService.dark$ | async"
  (clickOutside)="resetMenu()">
  <div class="container">
    <div
      class="navbar-brand is-justify-content-space-between is-mx-3 is-mx-tablet-4 is-mx-desktop-auto is-px-tablet-2 is-px-desktop-0">
      <a
        role="button"
        class="navbar-burger burger ml-0 is-hidden-tablet"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbar-menu"
        (click)="userMenuActive = false; searchMenuActive = false; menuActive = !menuActive"
        [class.is-active]="menuActive">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>

      <a class="navbar-item is-pl-desktop-0" href="/">
        <img
          class="logo-yellow"
          ngSrc="https://cdn.hestia.earth/prod/assets/images/logo-yellow.svg"
          [priority]="true"
          width="180"
          height="69" />
        <img
          class="logo-white is-hidden"
          ngSrc="https://cdn.hestia.earth/prod/assets/images/logo-white.svg"
          [priority]="false"
          width="180"
          height="69" />
        <img
          class="logo-blue is-hidden"
          ngSrc="https://cdn.hestia.earth/prod/assets/images/logo-blue.svg"
          [priority]="false"
          width="180"
          height="69" />
      </a>

      <div class="is-flex is-align-items-center is-hidden-mobile is-hidden-desktop">
        <ng-container *ngTemplateOutlet="partners"></ng-container>
      </div>

      <div class="is-flex is-align-items-center is-ml-mobile-auto is-mr-3 is-hidden-desktop">
        <a
          class="has-text-white is-p-2 is-mr-2 is-hidden-tablet"
          aria-haspopup="true"
          aria-controls="navbar-search"
          (click)="menuActive = false; userMenuActive = false; searchMenuActive = !searchMenuActive">
          <img
            loading="lazy"
            class="is-align-middle logo-yellow is-hidden"
            height="24"
            width="24"
            src="https://cdn.hestia.earth/prod/assets/images/search-yellow.svg" />
          <img
            loading="lazy"
            class="is-align-middle logo-white is-hidden"
            height="24"
            width="24"
            src="https://cdn.hestia.earth/prod/assets/images/search-white.svg" />
          <img
            loading="lazy"
            class="is-align-middle logo-blue"
            height="24"
            width="24"
            src="https://cdn.hestia.earth/prod/assets/images/search-blue.svg" />
        </a>

        @if (usersService.user$ | async) {
          <a
            class="user-icon is-ml-2 has-text-white has-text-centered"
            aria-haspopup="true"
            aria-controls="navbar-users"
            (click)="menuActive = false; searchMenuActive = false; userMenuActive = !userMenuActive">
            <span>{{ userInitials$ | async }}</span>
          </a>
        } @else {
          <a class="signin-icon" routerLink="/users/profile">
            <img
              class="logo-blue"
              height="32"
              width="32"
              src="https://cdn.hestia.earth/prod/assets/images/profile-blue.svg" />
            <img
              class="logo-yellow is-hidden-tablet is-hidden-mobile"
              height="32"
              width="32"
              src="https://cdn.hestia.earth/prod/assets/images/profile-yellow.svg" />
            <img
              class="logo-white is-hidden"
              height="32"
              width="32"
              src="https://cdn.hestia.earth/prod/assets/images/profile-white.svg" />
          </a>
        }

        <a
          role="button"
          class="navbar-burger burger ml-0 is-hidden-mobile"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar-menu"
          (click)="userMenuActive = false; menuActive = !menuActive"
          [class.is-active]="menuActive">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
    </div>

    <div id="navbar-menu" class="navbar-menu is-primary" [class.is-active]="menuActive">
      <div class="navbar-end">
        <ng-container *ngTemplateOutlet="formatMenu"></ng-container>
        <ng-container *ngTemplateOutlet="platformMenu"></ng-container>
        <ng-container *ngTemplateOutlet="modelsMenu"></ng-container>
        <ng-container *ngTemplateOutlet="aboutMenu"></ng-container>

        @if (usersService.user$ | async; as user) {
          <div
            class="navbar-item is-ml-2 pointer has-dropdown is-hoverable is-hidden-mobile is-hidden-tablet-only"
            appNavbarDropdown>
            <a class="user-icon is-block has-text-centered w-100">
              <span>{{ userInitials$ | async }}</span>
            </a>
            <div class="navbar-dropdown is-right" (click)="menuActive = false">
              <ng-container *ngTemplateOutlet="userLinks"></ng-container>
            </div>
          </div>
        } @else {
          <a
            class="users-signin button is-secondary is-rounded is-ml-mobile-auto is-mx-tablet-3"
            routerLink="/users/signin">
            Sign In
          </a>
        }
      </div>
    </div>

    <div class="navbar-end is-ml-2 is-hidden-mobile is-hidden-tablet-only">
      <ng-container *ngTemplateOutlet="partners"></ng-container>
    </div>

    <div id="navbar-users" class="navbar-menu is-primary is-hidden-desktop" [class.is-active]="userMenuActive">
      <div class="navbar-dropdown">
        <ng-container *ngTemplateOutlet="userLinks"></ng-container>
      </div>
    </div>

    <div id="navbar-search" class="navbar-menu is-hidden-tablet is-p-3" [class.is-active]="searchMenuActive">
      <div class="has-text-right">
        <a class="has-text-grey-light" (click)="searchMenuActive = false">
          <fa-icon icon="times" size="2x"></fa-icon>
        </a>
      </div>

      <app-search-bar></app-search-bar>
    </div>
  </div>
</nav>

<ng-template #formatMenu>
  <div class="navbar-item has-dropdown is-hoverable" appNavbarDropdown>
    <a class="navbar-link is-arrowless">Data Format</a>
    <div class="navbar-dropdown">
      <a class="navbar-item" routerLink="/schema">Schema</a>
      <a class="navbar-item" routerLink="/glossary">Glossary</a>
    </div>
  </div>
</ng-template>

<ng-template #platformMenu>
  <div class="navbar-item has-dropdown is-hoverable" appNavbarDropdown>
    <a class="navbar-link is-arrowless">Data Platform</a>
    <div class="navbar-dropdown" (click)="menuActive = false">
      <a class="navbar-item" routerLink="/explorer">Data Explorer</a>
      <a class="navbar-item" routerLink="/upload">How to Upload</a>
    </div>
  </div>
</ng-template>

<ng-template #modelsMenu>
  <div class="navbar-item has-dropdown is-hoverable" appNavbarDropdown>
    <a class="navbar-link is-arrowless">Models</a>
    <div class="navbar-dropdown">
      <a class="navbar-item" routerLink="/calculation/lca-models">Use Our Models</a>
      <a class="navbar-item" href="/docs/" target="_blank">
        <span>Model Documentation</span>
        <fa-icon class="no-print ml-2" icon="external-link-alt" size="sm"></fa-icon>
      </a>
      <a class="navbar-item" routerLink="/calculation/orchestrator">Modelling Approach</a>
    </div>
  </div>
</ng-template>

<ng-template #aboutMenu>
  <div class="navbar-item has-dropdown is-hoverable" appNavbarDropdown>
    <a class="navbar-link is-arrowless">About</a>
    <div class="navbar-dropdown">
      <a class="navbar-item" routerLink="about">About Us</a>
      <a class="navbar-item" routerLink="/jobs">Jobs</a>
    </div>
  </div>
</ng-template>

<ng-template #userLinks let-user>
  <a class="navbar-item" routerLink="/files">My Uploads</a>
  <a class="navbar-item" routerLink="/users/profile">My Account Settings</a>

  @if (adminDashboardEnabled()) {
    <hr class="navbar-divider" />
    <a class="navbar-item" [routerLink]="adminDashboardLink().url" [queryParams]="adminDashboardLink().queryParams">
      Admin Dashboard
    </a>
  }

  <hr class="navbar-divider" />
  <a class="navbar-item" (click)="usersService.signOut()">Sign Out</a>
</ng-template>

<ng-template #partners>
  <div class="partners-container is-flex">
    <a
      class="partner-logo is-oms"
      href="https://www.oxfordmartin.ox.ac.uk/food-sustainability/"
      target="_blank"
      title="Oxford Martin School"></a>
    <a class="partner-logo is-login5" href="https://login5.org/" target="_blank" title="Login5 Foundation"></a>
    <a class="partner-logo is-wwf" href="https://wwf.panda.org/" target="_blank" title="WWF"></a>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { close } from '@sentry/angular-ivy';

@Injectable()
export class ServiceUnavailableInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(error => throwError(error)));
  }

  private redirectToMaintenance() {
    // make sure sentry does not send error reports when maintenance is activated
    close();
    return this.router.navigateByUrl('/maintenance');
  }
}

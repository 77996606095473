import { IEnv } from './environment.interface';

export const environment: IEnv = {
  production: true,
  name: 'Staging',
  robots: false,
  jsonldUrl: 'https://jsonld-staging.hestia.earth',
  api: {
    baseUrl: 'https://api-staging.hestia.earth'
  },
  dataApi: {
    baseUrl: 'https://data-staging.hestia.earth'
  },
  downloadApi: {
    baseUrl: 'https://download-staging.hestia.earth'
  },
  orchestratorConfigUrl: 'https://gitlab.com/hestia-earth/hestia-engine-orchestrator/-/snippets/2106345/raw/master',
  logGroupStage: 'staging',
  sentryApi: {
    dsn: 'https://a2fd60df49ed4830bc5af0f11c6ee6cb@o411280.ingest.sentry.io/5286283',
    release: '33077ade',
    environment: 'staging'
  },
  mapsApiKey: 'AIzaSyBstTYxZtfsGedDwfZBV-X7TDmtui3NTGc',
  reCaptchaKey: '6Lek3fIUAAAAAPG-cBI-FTxyJFAzLVjQniixf_Dw'
};

<app-navbar></app-navbar>

@if (loading()) {
  <progress class="fixed-top is-mb-0 progress is-small is-info"></progress>
}

<router-outlet></router-outlet>

<app-footer></app-footer>

<he-toast></he-toast>

<app-general-notice-banner></app-general-notice-banner>

<app-download-shelf-dialog-container />

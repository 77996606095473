import { FormArray } from '@angular/forms';
import get from 'lodash.get';
import { IImpactAssessmentJSONLD, ITermJSONLD, NodeType } from '@hestia-earth/schema';
import { dayMs, isNumber, toPrecision } from '@hestia-earth/utils';
import { propertyValue } from '@hestia-earth/utils/dist/term';
import { InjectionToken } from '@angular/core';

export const cdnImageUrl = (path: string) => `https://cdn.hestia.earth/prod/assets/images/${path}`;

export const defaultPage = (page?: string) => parseInt(page || '1', 10);

export const loadScript = (src?: string) =>
  new Promise<any>((resolve, reject) => {
    if (!src || document.body.querySelectorAll('script[src="' + src + '"]').length > 0) {
      return resolve({});
    }

    const script = document.createElement('script');
    script.onload = resolve;
    script.onerror = reject;
    script.src = src;
    document.body.appendChild(script);
  });

export const downloadFile = (url: string, fileName: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

export const downloadLimit = 10000;

export const DOWNLOAD_LIMIT = new InjectionToken('DOWNLOAD_LIMIT', {
  providedIn: 'root',
  factory: () => downloadLimit
});

export const toFormData = (data: any, formData = new FormData()) => {
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key];
      if (typeof value === 'object') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }
  }
  return formData;
};

export const toSearchParams = (params: any = {}) => {
  const searchParams = new URLSearchParams();
  Object.keys(params)
    .filter(key => typeof params[key] !== 'undefined')
    .forEach(key => searchParams.append(key, `${params[key]}`));
  return searchParams;
};

export const animateCounter = (total: number, callback: (count: number) => void) => {
  let animationDuration = 10;
  const step = Math.pow(10, `${total}`.length - 1);
  const maxSteps = parseInt(`${total / step}`, 10);
  let current = 0;
  const interval = setInterval(() => {
    callback(current);
    current += step / animationDuration;
    if (current > maxSteps * step) {
      animationDuration = 100;
    }
    if (current > total) {
      callback(total);
      clearInterval(interval);
    }
  }, animationDuration);
};

export const sortByFn =
  (key: string, asc: boolean) =>
  <T>(a: T, b: T): number => {
    const aValue = get(a, key, '') || '';
    const bValue = get(b, key, '') || '';
    return asc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  };

export const parseNodeType = (type: string) =>
  Object.values(NodeType).find(v => v.toString().toLowerCase() === (type || '').toLowerCase());

export const nodeToAggregationFilename = (node: any, countryId: string) => {
  const productId = node['@id']?.split('-')[0];
  const timestamp = node['@id']?.split('-').pop();
  return [productId, countryId, node?.startDate, node?.endDate, isNumber(timestamp) ? timestamp : null]
    .filter(Boolean)
    .join('-');
};

export const hoursBefore = (date: Date, hours = 1) => {
  date.setHours(date.getHours() - hours);
  return date;
};

export const worldRegion = Object.freeze({
  '@type': NodeType.Term,
  '@id': 'region-world',
  name: 'World'
}) as ITermJSONLD;

export const findImpact = ({ impacts }: Pick<IImpactAssessmentJSONLD, 'impacts'>, termName: string) =>
  (impacts || []).find(({ term }) => term.name === termName);

export const impactValue = (node: Pick<IImpactAssessmentJSONLD, 'impacts'>, termName: string) =>
  toPrecision((propertyValue(findImpact(node, termName)?.value) as number) || 0);

const defaultCacheExpires = 7 * dayMs;

export const isCacheExpired = (date?: Date | string, duration = defaultCacheExpires) =>
  date ? new Date().getTime() - new Date(date).getTime() > duration : true;

export const keysLength = (object: Record<string, any> | any[]) =>
  Array.isArray(object) ? object.length : Object.keys(object).length;

export const moveItemInFormArray = (formArray: FormArray, fromIndex: number, toIndex: number) => {
  const dir = toIndex > fromIndex ? 1 : -1;

  const item = formArray.at(fromIndex);
  for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
    const current = formArray.at(i + dir);
    formArray.setControl(i, current);
  }
  formArray.setControl(toIndex, item);
};

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { contactUsEmail, gitHome } from '@hestia-earth/ui-components';

import { FooterVisibilityService } from './footer-visibility.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, NgOptimizedImage]
})
export class FooterComponent {
  private router = inject(Router);
  private footerVisibilityService = inject(FooterVisibilityService);

  protected gitHome = gitHome;
  protected contactUsEmail = contactUsEmail;
  protected isVisible = this.footerVisibilityService.isVisible;

  protected isCurrentUrl(url: string) {
    return this.router.url === url;
  }
}

import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { filter, switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DOWNLOAD_API_PATH } from '../../utils/constants';
import { DownloadTokenStore } from '../state/download-token.store';

@Injectable()
export class DownloadApiInterceptor implements HttpInterceptor {
  private readonly downloadTokenStore = inject(DownloadTokenStore);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const addDownloadBearer = request.url.includes(DOWNLOAD_API_PATH);

    return addDownloadBearer
      ? this.downloadTokenStore.downloadToken$.pipe(
          filter(token => !!token),
          take(1),
          switchMap(token => {
            return addDownloadBearer
              ? next.handle(
                  request.clone({
                    headers: request.headers.set('Authorization', `Bearer ${token}`)
                  })
                )
              : next.handle(request);
          })
        )
      : next.handle(request);
  }
}

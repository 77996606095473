import { inject, Injectable } from '@angular/core';
import { DownloadItem, DownloadItemStatus, InitDownloadRequest } from '../models/download-api.contracts';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DOWNLOAD_API_PATH, UNLOGGED_USER_ID_HEADER } from '../../utils/constants';
import { LOCAL_DOWNLOAD_TOKEN } from '../state/local-download.token';
import { environment } from '../../../../environments/environment';
import { headerToken, UsersService } from '../../../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadApiService {
  private readonly httpClient = inject(HttpClient);
  private readonly localDownloadToken = inject(LOCAL_DOWNLOAD_TOKEN);
  private readonly handler: HttpBackend = inject(HttpBackend);
  private readonly usersService = inject(UsersService);

  initDownload(request: InitDownloadRequest): Observable<DownloadItem> {
    return this.httpClient.post<DownloadItem>(`${DOWNLOAD_API_PATH}/files/init`, {
      title: request.title,
      types: Array.isArray(request.type) ? request.type : [request.type],
      data: request.data
    });
  }

  getDownloadItem(id: string): Observable<DownloadItem> {
    return this.httpClient.get<DownloadItem>(`${DOWNLOAD_API_PATH}/files/${id}`);
  }

  queryDownloadItems(query?: {
    page?: number;
    size?: number;
    statuses?: DownloadItemStatus[];
  }): Observable<{ items: DownloadItem[]; total: number }> {
    return this.httpClient.get<{ items: DownloadItem[]; total: number }>(`${DOWNLOAD_API_PATH}/files`, {
      params: query ?? {}
    });
  }

  emailNotification(email: string): Observable<void> {
    return this.httpClient.put<void>(`${DOWNLOAD_API_PATH}/user/mail`, { additional_mail: email });
  }

  cancelDownload(id: string): Observable<void> {
    return this.httpClient.post<void>(`${DOWNLOAD_API_PATH}/files/${id}/cancel`, {});
  }

  cancelDownloads(): Observable<{
    success: string[];
  }> {
    return this.httpClient.post<{
      success: string[];
    }>(`${DOWNLOAD_API_PATH}/files/cancel`, {});
  }

  deleteDownload(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${DOWNLOAD_API_PATH}/files/${id}`);
  }

  clearDownloads(): Observable<{
    success: string[];
  }> {
    return this.httpClient.delete<{
      success: string[];
    }>(`${DOWNLOAD_API_PATH}/files`);
  }

  getDownloadToken(): Observable<{ token: string }> {
    // to avoid http interceptors
    const client = new HttpClient(this.handler);

    const bearerToken = !this.usersService.token ? '' : this.usersService.token;

    const headers = bearerToken
      ? { [headerToken]: bearerToken }
      : {
          [UNLOGGED_USER_ID_HEADER]: this.localDownloadToken
        };
    return client.get<{ token: string }>(`${environment.api.baseUrl}/download/token`, {
      headers
    });
  }
}

import { inject, Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { SocketService } from '../websockets/socket.service';
import { concatMap, distinctUntilChanged, filter, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { DownloadItem, DownloadItemWebsocketMessage, InitDownloadRequest } from '../models/download-api.contracts';
import { Observable } from 'rxjs';
import { DownloadApiService } from '../services/download-api.service';
import { JSONLD, NodeType } from '@hestia-earth/schema';
import { DownloadItemModel } from '../../ui/download-item/download-item.component';
import { SubscriptionEvent } from '../websockets/types';
import omit from 'lodash.omit';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { LOCAL_DOWNLOAD_TOKEN } from './local-download.token';
import { NodeBulkDownload } from '../models/node-bulk.download';
import { HeToastService } from '@hestia-earth/ui-components';
import { DownloadShelfHeaderState } from '../../ui/download-shelf-header/download-shelf-header.component';
import { DownloadTokenStore } from './download-token.store';

export const isJSONLD = (nodeBulkDownload: NodeBulkDownload): nodeBulkDownload is JSONLD<NodeType> => {
  return nodeBulkDownload['@id'] !== undefined;
};

export const toDownloadData = (
  nodeBulkDownload: NodeBulkDownload,
  aggregated: boolean
): {
  type: string;
  id: string;
  aggregated: boolean;
}[] => {
  if (Array.isArray(nodeBulkDownload)) {
    return nodeBulkDownload.map(node =>
      isJSONLD(node)
        ? { type: node['@type'], id: node['@id'], aggregated }
        : {
            type: node.type,
            id: node.id,
            aggregated
          }
    );
  } else {
    return isJSONLD(nodeBulkDownload)
      ? [{ type: nodeBulkDownload['@type'], id: nodeBulkDownload['@id'], aggregated }]
      : [{ type: nodeBulkDownload.type, id: nodeBulkDownload.id, aggregated }];
  }
};

export const isDownloadItemCompleted = (item: DownloadItem) =>
  ['PROCESSED', 'EXPIRED', 'FAILED', 'FINISHED', 'CANCELLED'].includes(item.status);

export const hasErrorDetail = (err: unknown): err is { error: { detail: string } } => {
  return err && typeof err === 'object' && 'error' in err && typeof err.error === 'object' && 'detail' in err.error;
};

type DownloadShelfDialog = 'clearAll' | 'cancelAll' | 'cancel' | 'delete';

export interface DownloadShelfState {
  expanded: boolean;
  visible: boolean;
  downloadItems: Record<string, DownloadItem>;
  total: number;
  emailSent: boolean;
  emailValidation: boolean;
  loadMoreValidation: boolean;
  dialogs: Record<
    DownloadShelfDialog,
    {
      open?: boolean;
      validation?: boolean;
      payload?: any;
    }
  >;
}

export const initialDownloadShelfDialogState: DownloadShelfState = {
  expanded: false,
  visible: false,
  downloadItems: {},
  emailSent: false,
  emailValidation: false,
  loadMoreValidation: false,
  total: 0,
  dialogs: {
    clearAll: {},
    cancelAll: {},
    cancel: {},
    delete: {}
  }
};

@Injectable({
  providedIn: 'root'
})
export class DownloadShelfStore extends ComponentStore<DownloadShelfState> {
  private readonly socketService = inject(SocketService);
  private readonly localDownloadToken = inject(LOCAL_DOWNLOAD_TOKEN);
  private readonly hasEmailSubscriptionKey = 'hasEmailSubscription';
  private readonly downloadApiService = inject(DownloadApiService);
  private readonly toastService = inject(HeToastService);
  private readonly downloadTokenStore = inject(DownloadTokenStore);

  private readonly take = 50;

  constructor() {
    super(initialDownloadShelfDialogState);

    this.downloadTokenStore.downloadToken$
      .pipe(
        filter(token => !!token),
        takeUntilDestroyed(),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.loadDownloadItems();
      });

    const hasEmailSubscription = localStorage.getItem(this.hasEmailSubscriptionKey) === this.localDownloadToken;
    this.patchState({ emailSent: hasEmailSubscription });

    this.listenForDownloadUpdates();
  }

  readonly downloadItems$ = this.select(({ downloadItems }) => Object.values(downloadItems));

  readonly downloadItemsModels = this.selectSignal(({ downloadItems }): DownloadItemModel[] =>
    Object.values(downloadItems)
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      .map(item => ({
        id: item.id,
        title: item.title,
        status: item.status,
        progress: item.progress,
        downloadUrl: item.download_url,
        type: item.types.length > 1 ? 'json' : item.types?.[0]
      }))
  );

  readonly expanded = this.selectSignal(({ expanded }) => expanded);

  readonly total = this.selectSignal(({ total }) => total);

  readonly totalFetched = this.selectSignal(({ downloadItems }) => Object.values(downloadItems).length);

  readonly completedCount = this.selectSignal(
    ({ downloadItems }) => Object.values(downloadItems).filter(isDownloadItemCompleted).length
  );

  readonly progress = this.selectSignal(this.totalFetched, this.state, (total, { downloadItems }) => {
    const completed = Object.values(downloadItems).filter(isDownloadItemCompleted).length;
    const inProgressSum = Object.values(downloadItems)
      .filter(item => item.status === 'PROCESSING')
      .reduce((acc, item) => acc + item.progress, 0);

    const sum = completed + inProgressSum;

    return total && sum ? (sum / total) * 100 : 0;
  });

  readonly failed = this.selectSignal(
    ({ downloadItems }) => Object.values(downloadItems).filter(item => item.status === 'FAILED').length
  );

  readonly preparing = this.selectSignal(
    ({ downloadItems }) => Object.values(downloadItems).filter(item => item.status === 'QUEUED').length
  );

  readonly downloadState = this.selectSignal(
    this.totalFetched,
    this.completedCount,
    this.preparing,
    (total, completed, preparing): DownloadShelfHeaderState =>
      total === completed ? 'done' : preparing > 0 ? 'prepare' : 'downloading'
  );

  readonly emailSent = this.selectSignal(({ emailSent }) => emailSent);

  readonly emailValidation = this.selectSignal(({ emailValidation }) => emailValidation);

  readonly clearAllDialog = this.selectSignal(({ dialogs }) => dialogs.clearAll);
  readonly cancelAllDialog = this.selectSignal(({ dialogs }) => dialogs.cancelAll);
  readonly cancelDialog = this.selectSignal(({ dialogs }) => dialogs.cancel);
  readonly deleteDialog = this.selectSignal(({ dialogs }) => dialogs.delete);

  readonly loadMoreValidation = this.selectSignal(({ loadMoreValidation }) => loadMoreValidation);

  readonly visible = this.selectSignal(({ visible }) => visible);

  readonly openDialog = (dialog: DownloadShelfDialog, payload?: any) =>
    this._updateDialog({
      dialog,
      open: true,
      payload
    });
  readonly closeDialog = (dialog: DownloadShelfDialog) => this._updateDialog({ dialog, open: false });

  readonly listenForDownloadUpdates = this.effect(trigger$ =>
    trigger$.pipe(
      switchMap(() =>
        this.socketService.listen<SubscriptionEvent<DownloadItemWebsocketMessage>>().pipe(
          withLatestFrom(this.state$),
          tap(([{ body }, { downloadItems }]) => {
            const item = downloadItems[body.file_id];
            item &&
              item.status !== 'CANCELLED' &&
              this.patchState(state => ({
                downloadItems: {
                  ...state.downloadItems,
                  [body.file_id]: {
                    ...state.downloadItems[body.file_id],
                    status: body.file_status,
                    progress: body.progress
                  }
                }
              }));
            if (body.file_status === 'FINISHED') {
              setTimeout(() => this.updateFinishedItem(body.file_id), 500);
            }
          })
        )
      )
    )
  );

  readonly initDownload = this.effect(
    (
      request$: Observable<{
        request: Omit<InitDownloadRequest, 'data'> & {
          data: NodeBulkDownload;
          aggregated: boolean;
        };
        callback?: (item: DownloadItem) => void;
      }>
    ) =>
      request$.pipe(
        mergeMap(({ request, callback }) =>
          this.downloadApiService
            .initDownload({
              ...request,
              data: toDownloadData(request.data, request.aggregated)
            })
            .pipe(
              tapResponse({
                next: item => {
                  this.patchState(state => ({
                    downloadItems: {
                      ...state.downloadItems,
                      [item.id]: item
                    },
                    total: state.total + 1,
                    visible: true,
                    expanded: true
                  }));
                  this.socketService.subscribeTo({
                    event_type: 'start_progress_subscription',
                    body: { file_id: item.id }
                  });
                  callback?.(item);
                },
                error: console.error
              })
            )
        )
      )
  );

  readonly loadDownloadItems = this.effect(trigger$ =>
    trigger$.pipe(
      switchMap(_ =>
        this.downloadApiService
          .queryDownloadItems({
            statuses: ['QUEUED', 'PROCESSING', 'PROCESSED', 'EXPIRED', 'FAILED', 'CANCELLED'],
            size: this.take
          })
          .pipe(
            tap(({ items, total }) => {
              const downloadItems = items.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});

              const unfinishedDownloads = items.filter(item => !isDownloadItemCompleted(item));
              unfinishedDownloads.forEach(item =>
                this.socketService.subscribeTo({
                  event_type: 'start_progress_subscription',
                  body: { file_id: item.id }
                })
              );

              this.patchState(items => ({
                downloadItems: downloadItems,
                total,
                visible: !!total,
                expanded: !!unfinishedDownloads.length
              }));
            })
          )
      )
    )
  );

  readonly updateFinishedItem = this.effect((id$: Observable<string>) =>
    id$.pipe(
      concatMap(id =>
        this.downloadApiService.getDownloadItem(id).pipe(
          tapResponse({
            next: item => {
              this.patchState(state => ({
                downloadItems: {
                  ...state.downloadItems,
                  [item.id]: item
                }
              }));
            },
            error: error => this._handleError(error)
          })
        )
      )
    )
  );

  readonly sendEmail = this.effect((email$: Observable<string>) =>
    email$.pipe(
      tap(() => this.patchState({ emailValidation: true })),
      switchMap(email =>
        this.downloadApiService.emailNotification(email).pipe(
          tapResponse({
            next: () => {
              localStorage.setItem(this.hasEmailSubscriptionKey, this.localDownloadToken);
              this.patchState({ emailSent: true });
            },
            error: error => this._handleError(error),
            finalize: () => this.patchState({ emailValidation: false })
          })
        )
      )
    )
  );

  readonly cancel = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      tap(() => this._updateDialog({ dialog: 'cancel', validation: true })),
      withLatestFrom(toObservable(this.cancelDialog)),
      switchMap(([_, { payload }]) =>
        this.downloadApiService.cancelDownload(payload.id).pipe(
          tapResponse({
            next: () => {
              this.patchState(state => ({
                downloadItems: {
                  ...state.downloadItems,
                  [payload.id]: {
                    ...state.downloadItems[payload.id],
                    status: 'CANCELLED'
                  }
                }
              }));
            },
            error: err => {
              console.error(err);
              if (hasErrorDetail(err)) {
                this.toastService.error(err.error.detail);
              }
            },
            finalize: () => this._updateDialog({ dialog: 'cancel', validation: false, open: false })
          })
        )
      )
    )
  );

  readonly delete = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      tap(() => this._updateDialog({ dialog: 'delete', validation: true, open: true })),
      withLatestFrom(toObservable(this.deleteDialog)),
      switchMap(([_, { payload }]) =>
        this.downloadApiService.deleteDownload(payload.id).pipe(
          tapResponse({
            next: () => {
              this.patchState(state => ({
                downloadItems: omit(state.downloadItems, [payload.id]),
                total: state.total - 1
              }));
            },
            error: error => this._handleError(error),
            finalize: () => this._updateDialog({ dialog: 'delete', validation: false, open: false })
          })
        )
      )
    )
  );

  readonly clearAll = this.effect(trigger$ =>
    trigger$.pipe(
      tap(() => this._updateDialog({ dialog: 'clearAll', validation: true })),

      switchMap(() => {
        return this.downloadApiService.clearDownloads().pipe(
          tapResponse({
            next: ({ success }) => {
              this.patchState(state => ({
                downloadItems: omit(state.downloadItems, success)
              }));
            },
            error: error => this._handleError(error),
            finalize: () => this._updateDialog({ dialog: 'clearAll', validation: false, open: false })
          })
        );
      })
    )
  );

  readonly close = this.effect(trigger$ =>
    trigger$.pipe(
      tap(() => {
        const processingItems = this.downloadItemsModels().filter(
          x => x.status === 'PROCESSING' || x.status === 'QUEUED'
        );
        if (processingItems.length) {
          this.openDialog('cancelAll');
        } else {
          this.patchState({ visible: false });
        }
      })
    )
  );

  readonly cancelAll = this.effect(trigger$ =>
    trigger$.pipe(
      tap(() => this._updateDialog({ dialog: 'cancelAll', validation: true })),
      switchMap(() => {
        return this.downloadApiService.cancelDownloads().pipe(
          tapResponse({
            next: ({ success }) => {
              this.patchState(state => ({
                downloadItems: omit(state.downloadItems, success),
                visible: false
              }));
            },
            error: error => this._handleError(error),
            finalize: () => {
              this._updateDialog({ dialog: 'cancelAll', validation: false, open: false });
              this.patchState({ visible: false });
            }
          })
        );
      })
    )
  );

  readonly itemLoaded = this.effect((id$: Observable<string>) =>
    id$.pipe(
      withLatestFrom(this.downloadItems$),
      filter(([id]) => {
        const items = this.downloadItemsModels();
        const total = this.total();

        return items.length < total && items[items.length - 2]?.id === id;
      }),
      tap(() => this.patchState({ loadMoreValidation: true })),
      switchMap(([_, items]) => {
        const skip = items.length;

        const nextPage = Math.floor(skip / this.take) + 1;

        return this.downloadApiService
          .queryDownloadItems({
            page: nextPage,
            statuses: ['QUEUED', 'PROCESSING', 'PROCESSED', 'EXPIRED', 'FAILED', 'CANCELLED'],
            size: this.take
          })
          .pipe(
            tapResponse({
              next: ({ items: newItems }) => {
                const downloadItems = newItems.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
                this.patchState(state => ({
                  downloadItems: {
                    ...state.downloadItems,
                    ...downloadItems
                  }
                }));
              },
              error: error => this._handleError(error),
              finalize: () => this.patchState({ loadMoreValidation: false })
            })
          );
      })
    )
  );

  readonly setVisibility = this.updater((state, visible: boolean) => ({ ...state, visible }));

  private _handleError = (err: unknown) => {
    console.error(err);
    if (hasErrorDetail(err)) {
      this.toastService.error(err.error.detail);
    }
  };

  private readonly _updateDialog = this.updater(
    (
      state,
      r: {
        dialog: DownloadShelfDialog;
        open?: boolean;
        validation?: boolean;
        payload?: any;
      }
    ) => ({
      ...state,
      dialogs: {
        ...state.dialogs,
        [r.dialog]: {
          ...state.dialogs[r.dialog],
          ...r
        }
      }
    })
  );
}

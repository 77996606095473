import { Injectable } from '@angular/core';
import { HeGlossaryService } from '@hestia-earth/ui-components';
import { IGlossaryLookup } from '@hestia-earth/api';

import { environment } from '../../environments/environment';

const path = `${environment.api.baseUrl}/glossary`;

export class GlossaryLookupListResult {
  count = 0;
  results: IGlossaryLookup[] = [];
}

@Injectable({
  providedIn: 'root'
})
export class GlossaryService extends HeGlossaryService {
  public list$() {
    return this.http.get<GlossaryLookupListResult>(`${path}/lookups`);
  }
}

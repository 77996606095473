/* eslint-disable complexity */
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  init as initSentry,
  captureException,
  browserTracingIntegration,
  browserApiErrorsIntegration
} from '@sentry/angular-ivy';

import { environment } from './environments/environment';

const handleSentryError = error => {
  // Try to unwrap zone.js error.
  // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
  if (error && error.ngOriginalError) {
    error = error.ngOriginalError;
  }

  // We can handle messages and Error objects directly.
  if (typeof error === 'string' || error instanceof Error) {
    return error;
  }

  // If it's http module error, extract as much information from it as we can.
  if (error instanceof HttpErrorResponse) {
    // The `error` property of http exception can be either an `Error` object, which we can use directly...
    if (error.error instanceof Error) {
      return error.error;
    }

    // ... or an`ErrorEvent`, which can provide us with the message but no stack...
    if (error.error instanceof ErrorEvent) {
      return error.error.message;
    }

    // ...or the request body itself, which we can use as a message instead.
    if (typeof error.error === 'string') {
      return `Server returned code ${error.status} with body "${error.error}"`;
    }

    // If we don't have any detailed information, fallback to the request message itself.
    return error.message;
  }

  // Skip if there's no error, and let user decide what to do with it.
  return null;
};

const ignoreRobots = () => {
  try {
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'robots');
    meta.setAttribute('content', environment.robots ? 'index, follow' : 'noindex, nofollow');
    document.getElementsByTagName('head')[0].appendChild(meta);
  } catch (err) {
    // ignore errors
  }
};

ignoreRobots();

initSentry({
  ...environment.sentryApi,
  ignoreErrors: [
    // chatbot errors
    /messenger/,
    'Invariant Violation',
    'Network Error',
    // google maps error
    /maps\/api/,
    /MapsRequestError/,
    /GEOCODER_GEOCODE/,
    // browser errors
    /safari/,
    /chrome/,
    /urldefense/,
    /ipinfo/,
    'cdn.hestia.earth',
    /read-only/
  ],
  denyUrls: [
    /messenger\.js/i,
    /cdn\.hestia\.earth/i,
    // ignore errors on maintenance page
    /maintenance/i
  ],
  // replaysSessionSampleRate: environment.production ? 0.1 : 0.5,
  // replaysOnErrorSampleRate: 1.0,
  integrations: [
    // Making the website really slow
    // new Sentry.Replay(),
    browserApiErrorsIntegration({
      XMLHttpRequest: false
    }),
    browserTracingIntegration({
      tracingOrigins: [environment.api.baseUrl]
    })
  ],
  tracesSampleRate: 0.5
});

// capture loading chunk errors
const chunkFailedMessage = /Loading chunk [\d]+ failed/;

const clearCaches = async () => {
  const keys = await caches.keys();
  return Promise.all(keys.map(caches.delete));
};

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  extractError(error) {
    return handleSentryError(error);
  }

  handleError(error) {
    console.error(error);
    if (error) {
      if (chunkFailedMessage.test(error.message)) {
        // ignore errors when deleting cache
        clearCaches()
          .then(window.location.reload)
          .catch(() => null);
      } else {
        captureException(error?.originalError || error);
      }
    }
  }
}

import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { contactUsEmail } from '@hestia-earth/ui-components';
import { LocalStorageService } from 'ngx-webstorage';

const date = new Date(2023, 10, 15);
const key = `app-general-notice-banner-${date.toJSON().substring(0, 10)}`;

@Component({
  selector: 'app-general-notice-banner',
  templateUrl: './general-notice-banner.component.html',
  styleUrls: ['./general-notice-banner.component.scss'],
  standalone: true,
  imports: [FaIconComponent, DatePipe]
})
export class GeneralNoticeBannerComponent {
  public date = date;
  public contactUsEmail = contactUsEmail;
  public visible = true;

  constructor(private localStorage: LocalStorageService) {
    this.visible = this.localStorage.retrieve(key) !== false;
  }

  public hide() {
    this.visible = false;
    this.localStorage.store(key, this.visible);
  }
}

@if (isVisible()) {
  <footer class="footer no-print">
    <div class="has-background-secondary has-text-white is-px-desktop-5">
      <div class="container is-mx-3 is-mx-tablet-4 is-mx-desktop-auto is-px-tablet-2 is-px-desktop-0">
        <div>
          <img
            class="logo-white is-inline-block is-mx-auto"
            ngSrc="https://cdn.hestia.earth/prod/assets/images/logo-white.svg"
            [priority]="true"
            width="90"
            height="34" />
        </div>
        <div class="columns is-justify-content-space-between is-mt-5">
          <div class="column is-narrow">
            <p>Department of Biology &</p>
            <p>Oxford Martin School</p>
            <p>34 Broad Street</p>
            <p>Oxford, OX1 3BD</p>
            <p>United Kingdom</p>
            <p></p>
            <p class="is-my-4 is-py-2">
              <a [href]="'mailto:' + contactUsEmail">
                <b>{{ contactUsEmail }}</b>
              </a>
            </p>
            <div class="is-mb-4 is-size-7">
              <a class="social-icon is-gitlab" [href]="gitHome" target="_blank"></a>
              <a
                class="social-icon is-linkedin is-ml-2 is-ml-tablet-3"
                href="https://www.linkedin.com/company/hestia-earth"
                target="_blank"></a>
              <a
                class="social-icon is-twitter is-ml-2 is-ml-tablet-3"
                href="https://x.com/hestiaToolkit"
                target="_blank"></a>
            </div>
          </div>
          <div class="column is-narrow-desktop">
            <div class="columns is-multiline is-mobile is-variable is-2 is-6-widescreen">
              <div class="column is-narrow-desktop is-6-mobile is-4-tablet is-auto-desktop is-pl-desktop-0">
                <aside class="menu">
                  <ul class="menu-list">
                    <li class="is-mb-2">
                      <span class="is-size-6 has-text-weight-bold">Data Format</span>
                    </li>
                    <li><a class="is-px-0" routerLink="/schema">Schema</a></li>
                    <li><a class="is-px-0" routerLink="/glossary">Glossary</a></li>
                  </ul>
                </aside>
              </div>
              <div class="column is-narrow-desktop is-6-mobile is-4-tablet is-auto-desktop">
                <aside class="menu">
                  <ul class="menu-list">
                    <li class="is-mb-2">
                      <span class="is-size-6 has-text-weight-bold">Data Platform</span>
                    </li>
                    <li>
                      <a class="is-px-0" routerLink="/explorer" [class.is-active]="isCurrentUrl('/explorer')">
                        Data Explorer
                      </a>
                    </li>
                    <li><a class="is-px-0" routerLink="/upload">How to Upload</a></li>
                  </ul>
                </aside>
              </div>
              <div class="column is-narrow-desktop is-6-mobile is-4-tablet is-auto-desktop">
                <aside class="menu">
                  <ul class="menu-list">
                    <li class="mb-2">
                      <span class="is-size-6 has-text-weight-bold">Models</span>
                    </li>
                    <li>
                      <a
                        class="is-px-0"
                        routerLink="/calculation/lca-models"
                        [class.is-active]="isCurrentUrl('/calculation/lca-models')">
                        Use Our Models
                      </a>
                    </li>
                    <li>
                      <a class="is-px-0" routerLink="/docs" target="_blank" [class.is-active]="isCurrentUrl('/docs')">
                        Model Documentation
                      </a>
                    </li>
                    <li>
                      <a
                        class="is-px-0"
                        routerLink="/calculation/orchestrator"
                        [class.is-active]="isCurrentUrl('/calculation/orchestrator')">
                        Modelling Approach
                      </a>
                    </li>
                  </ul>
                </aside>
              </div>
              <div class="column is-narrow-desktop is-6-mobile is-4-tablet is-auto-desktop">
                <aside class="menu">
                  <ul class="menu-list">
                    <li class="is-mb-2">
                      <span class="is-size-6 has-text-weight-bold">About</span>
                    </li>
                    <li>
                      <a class="is-px-0" routerLink="/about" [class.is-active]="isCurrentUrl('/about')">About Us</a>
                    </li>
                    <li><a class="is-px-0" routerLink="/jobs" [class.is-active]="isCurrentUrl('/jobs')">Jobs</a></li>
                  </ul>
                </aside>
              </div>
              <div class="column is-narrow-desktop is-6-mobile is-4-tablet is-auto-desktop is-pr-desktop-0">
                <aside class="menu">
                  <ul class="menu-list">
                    <li class="is-mb-2">
                      <span class="is-size-6 has-text-weight-bold">Legal</span>
                    </li>
                    <li>
                      <a class="is-px-0" routerLink="/terms" [class.is-active]="isCurrentUrl('/terms')">Terms of Use</a>
                    </li>
                    <li>
                      <a class="is-px-0" routerLink="/privacy" [class.is-active]="isCurrentUrl('/privacy')">
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faGitlab, faGoogle, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  faCircle as farCircle,
  faClone as farClone,
  faComment as farComment,
  faFileZipper as farFileZipper,
  faIdBadge as farIdBadge,
  faUserCircle as farUserCircle
} from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faAngleUp,
  faArchive,
  faArrowUpRightFromSquare,
  faBookOpen,
  faBug,
  faBuilding,
  faCalculator,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCertificate,
  faChartBar,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faChevronRight,
  faCircle,
  faClipboard,
  faClipboardList,
  faClone,
  faCloudUploadAlt,
  faCodeCommit,
  faCog,
  faCommentAlt,
  faComments,
  faDatabase,
  faDownload,
  faDrawPolygon,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandAlt,
  faExternalLinkAlt,
  faEye,
  faFile,
  faFileArchive,
  faFileCsv,
  faFileDownload,
  faFileExport,
  faFileImport,
  faFilePdf,
  faFileWord,
  faFilter,
  faInfoCircle,
  faKey,
  faLink,
  faList,
  faLongArrowAltDown,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faLongArrowAltUp,
  faMagic,
  faMap,
  faMapMarked,
  faMapMarkedAlt,
  faMinus,
  faMinusCircle,
  faNotEqual,
  faPause,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faRedoAlt,
  faSave,
  faSearch,
  faSeedling,
  faShareNodes,
  faSignInAlt,
  faSort,
  faSortAmountUpAlt,
  faSpellCheck,
  faSpinner,
  faStream,
  faSync,
  faSyncAlt,
  faTag,
  faTh,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faToolbox,
  faTrash,
  faUpload,
  faUser,
  faUserCheck,
  faUserCircle,
  faUsers,
  faUserSecret,
  faUserShield
} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule]
})
export class FontawesomeModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      // free-brand
      faGitlab,
      faGoogle,
      faLinkedinIn,
      faTwitter,
      // free-regular
      farCircle,
      farClone,
      farComment,
      farIdBadge,
      farUserCircle,
      farFileZipper,
      // free-solid
      faAngleDoubleLeft,
      faAngleDoubleRight,
      faAngleDown,
      faAngleLeft,
      faAngleRight,
      faAngleUp,
      faAnglesLeft,
      faAnglesRight,
      faArchive,
      faBookOpen,
      faBuilding,
      faBug,
      faCalculator,
      faCaretDown,
      faCaretRight,
      faCaretUp,
      faChartBar,
      faCertificate,
      faCheck,
      faCheckCircle,
      faCheckDouble,
      faChevronRight,
      faChevronDown,
      faCircle,
      faClipboard,
      faClipboardList,
      faClone,
      faCloudUploadAlt,
      faCodeCommit,
      faCog,
      faComments,
      faCommentAlt,
      faDatabase,
      faDownload,
      faDrawPolygon,
      faEdit,
      faEllipsisV,
      faEnvelope,
      faExclamationCircle,
      faExclamationTriangle,
      faPause,
      faExpandAlt,
      faExternalLinkAlt,
      faEye,
      faFile,
      faFileArchive,
      faFileCsv,
      faFileDownload,
      faFileExport,
      faFileImport,
      faFilePdf,
      faFileWord,
      faFilter,
      faInfoCircle,
      faKey,
      faLink,
      faList,
      faLongArrowAltDown,
      faLongArrowAltUp,
      faLongArrowAltLeft,
      faLongArrowAltRight,
      faMagic,
      faMap,
      faMapMarked,
      faMapMarkedAlt,
      faMinus,
      faMinusCircle,
      faNotEqual,
      faPlus,
      faPlusCircle,
      faQuestionCircle,
      faRedoAlt,
      faSave,
      faSearch,
      faSeedling,
      faSpellCheck,
      faSignInAlt,
      faSort,
      faSortAmountUpAlt,
      faSpinner,
      faStream,
      faSync,
      faSyncAlt,
      faTag,
      faTh,
      faThumbtack,
      faTimes,
      faTimesCircle,
      faToolbox,
      faTrash,
      faUpload,
      faUser,
      faUsers,
      faUserCheck,
      faUserCircle,
      faUserSecret,
      faUserShield,
      faShareNodes,
      faArrowUpRightFromSquare,
      faExclamation
    );
  }
}

import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  private _isTransparent$ = new ReplaySubject<boolean>(1);
  private _isDark$ = new ReplaySubject<boolean>(1);

  public setTransparent(isTransparent: boolean) {
    this._isTransparent$.next(isTransparent);
  }

  public get transparent$() {
    return this._isTransparent$.asObservable();
  }

  public setDark(isDark: boolean) {
    this._isDark$.next(isDark);
  }

  public get dark$() {
    return this._isDark$.asObservable();
  }
}

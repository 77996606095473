import { Injectable, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IsActiveMatchOptions } from '@angular/router';
import { User, UserPermission, UserRole, hasPermission } from '@hestia-earth/api';
import { INavigationMenuLink } from '@hestia-earth/ui-components';

import { UsersService } from './users/users.service';

interface ILink extends INavigationMenuLink {
  userRoles: UserRole[];
  permission?: UserPermission;
  /**
   * Default link shown to the user, if access is granted.
   */
  isDefault?: boolean;
}

const activeMatchOptions: IsActiveMatchOptions = {
  matrixParams: 'ignored',
  queryParams: 'ignored',
  fragment: 'ignored',
  paths: 'exact'
};

const links: ILink[] = [
  {
    url: '/analyses',
    activeMatchOptions,
    title: 'Sensitivity analysis',
    userRoles: [UserRole.Admin]
  },
  {
    title: 'Reconciliations',
    activeMatchOptions,
    userRoles: [UserRole.Developer, UserRole.Reviewer, UserRole.Admin],
    permission: UserPermission.reconciliationsRead,
    epxanded: false,
    links: [
      {
        url: '/reconciliations/dashboard',
        activeMatchOptions,
        queryParams: {
          page: '1',
          sortBy: 'updatedAt',
          sortOrder: 'desc'
        },
        title: 'Dashboard'
      },
      {
        url: '/reconciliations/files',
        activeMatchOptions,
        title: 'Uploads'
      }
    ]
  },
  {
    title: 'Aggregations',
    activeMatchOptions,
    userRoles: [UserRole.Developer, UserRole.Reviewer, UserRole.Admin],
    permission: UserPermission.aggregationsRead,
    epxanded: false,
    links: [
      {
        url: '/aggregations/dashboard',
        activeMatchOptions,
        title: 'Dashboard'
      },
      {
        url: '/aggregations/files',
        activeMatchOptions,
        queryParams: {
          page: '1',
          sortBy: 'updatedAt',
          sortOrder: 'descending'
        },
        title: 'Uploads'
      }
    ]
  },
  {
    url: '/term/files',
    activeMatchOptions,
    queryParams: {
      archived: 'false',
      page: '1',
      sortBy: 'updatedAt',
      sortOrder: 'descending'
    },
    title: 'Terms Upload',
    userRoles: [UserRole.Admin]
  },
  {
    url: '/users',
    activeMatchOptions,
    title: 'Users',
    userRoles: [UserRole.Admin]
  },
  {
    url: '/users/files',
    activeMatchOptions,
    queryParams: {
      page: '1',
      sortBy: 'updatedAt',
      sortOrder: 'descending',
      archived: 'false',
      status: 'validateDataDone'
    },
    title: 'Uploads',
    isDefault: true,
    userRoles: [UserRole.Admin, UserRole.Reviewer]
  },
  {
    url: '/users/files/commit',
    activeMatchOptions,
    title: 'Sync Data Uploads',
    userRoles: [UserRole.Admin, UserRole.Reviewer]
  },
  {
    url: '/settings',
    activeMatchOptions,
    title: 'Settings',
    userRoles: [UserRole.Admin]
  },
  {
    url: '/source/environments',
    activeMatchOptions,
    title: 'Sync Sources',
    userRoles: [UserRole.Admin]
  },
  {
    title: 'Migrations',
    activeMatchOptions,
    userRoles: [UserRole.Admin],
    epxanded: false,
    links: [
      {
        title: 'Glossary',
        url: '/migrations/glossary',
        activeMatchOptions
      },
      {
        title: 'Schema',
        url: '/migrations/schema',
        activeMatchOptions
      }
    ]
  }
].sort((a, b) => a.title.localeCompare(b.title));

const enabledLinks = (user: User) =>
  links.filter(link =>
    [link.userRoles.includes(user.role), !link.permission || hasPermission(link.permission, user)].every(Boolean)
  );

const firstLink = (link: ILink) => (link.url ? link : link.links?.[0]?.url ? link.links[0] : null);

@Injectable({
  providedIn: 'root'
})
export class AdminNavigationService {
  private usersService = inject(UsersService);

  private user = toSignal(this.usersService.user$);

  public links = computed(() => (this.user() ? enabledLinks(this.user()) : []));
  public enabled = computed(() => this.links().length > 0);
  public defaultLink = computed(() => this.links().find(link => link.isDefault) || firstLink(this.links()[0]));
}

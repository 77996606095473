import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NodeType } from '@hestia-earth/schema';

import { AuthGuardService } from './users/auth-guard.service';
import { AuthGuardAdminGuard } from './users/auth-guard-admin.guard';
import { AuthGuardDeveloperGuard } from './users/auth-guard-developer.guard';

const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./home/home-page/home-page.component').then(m => m.HomePageComponent)
  },
  {
    path: 'jobs',
    loadComponent: () => import('./jobs-page/jobs-page.component').then(m => m.JobsPageComponent)
  },
  {
    path: 'maintenance',
    loadComponent: () => import('./maintenance-page/maintenance-page.component').then(m => m.MaintenancePageComponent)
  },
  {
    path: 'products',
    loadComponent: () => import('./products-page/products-page.component').then(m => m.ProductsPageComponent)
  },
  {
    path: 'privacy',
    loadComponent: () => import('./privacy-page/privacy-page.component').then(m => m.PrivacyPageComponent)
  },
  {
    path: 'terms',
    loadComponent: () => import('./terms-page/terms-page.component').then(m => m.TermsPageComponent)
  },
  {
    path: 'upload',
    loadComponent: () => import('./upload-page/upload-page.component').then(m => m.UploadPageComponent)
  },
  {
    path: 'release-notes',
    loadComponent: () =>
      import('./release-notes-page/release-notes-page.component').then(m => m.ReleaseNotesPageComponent)
  },
  {
    path: 'analyses',
    loadChildren: () => import('./analyses/analyses.routes'),
    canActivate: [AuthGuardDeveloperGuard]
  },
  {
    path: 'aggregation',
    loadChildren: () => import('./aggregation-engine/aggregation-engine.routes')
  },
  {
    path: 'aggregations',
    loadChildren: () => import('./aggregations/aggregations.routes'),
    canActivate: [AuthGuardDeveloperGuard]
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.routes')
  },
  {
    path: 'calculation',
    loadChildren: () => import('./hestia-engine/hestia-engine.routes')
  },
  {
    path: 'earth-engine',
    loadChildren: () => import('./earth-engine/earth-engine.routes'),
    canActivate: [AuthGuardDeveloperGuard]
  },
  {
    path: 'explorer',
    loadChildren: () => import('./explorer/explorer.routes')
  },
  {
    path: 'files',
    loadChildren: () => import('./files/files.routes'),
    canActivate: [AuthGuardService]
  },
  {
    path: 'glossary',
    loadChildren: () => import('./glossary/glossary.routes')
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.routes')
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.routes')
  },
  {
    path: 'migrations',
    loadChildren: () => import('./migrations/migrations.routes'),
    canActivate: [AuthGuardAdminGuard]
  },
  {
    path: 'node',
    loadChildren: () => import('./node/node.routes'),
    canActivate: [AuthGuardDeveloperGuard]
  },
  {
    path: 'schema',
    loadChildren: () => import('./schema/schema.routes')
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.routes'),
    canActivate: [AuthGuardAdminGuard]
  },
  {
    path: 'metrics',
    loadChildren: () => import('./metrics/metrics.routes')
  },
  {
    path: 'reconciliations',
    loadChildren: () => import('./reconciliations/reconciliations.routes'),
    canActivate: [AuthGuardDeveloperGuard]
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.routes')
  },
  {
    path: `${NodeType.Cycle.toLowerCase()}`,
    loadChildren: () => import('./cycles/cycles.routes')
  },
  {
    path: `${NodeType.ImpactAssessment.toLowerCase()}`,
    loadChildren: () => import('./impact-assessments/impact-assessments.routes')
  },
  {
    path: `${NodeType.Site.toLowerCase()}`,
    loadChildren: () => import('./sites/sites.routes')
  },
  {
    path: `${NodeType.Source.toLowerCase()}`,
    loadChildren: () => import('./sources/sources.routes')
  },
  {
    path: `${NodeType.Term.toLowerCase()}`,
    loadChildren: () => import('./terms/terms.routes')
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      useHash: false,
      enableTracing: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

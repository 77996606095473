import { Injectable, inject } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { isDeveloper } from '@hestia-earth/api';
import { map, tap } from 'rxjs/operators';

import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardDeveloperGuard {
  private router = inject(Router);
  private usersService = inject(UsersService);

  canActivate(_r, state: RouterStateSnapshot) {
    return this.usersService.user$.pipe(
      tap(user => (this.usersService.redirectUrl = user ? null : state.url)),
      map(user =>
        user ? isDeveloper(user) || this.router.parseUrl('/users/profile') : this.router.parseUrl('/users/signin')
      )
    );
  }
}

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DownloadShelfStore } from '../data-access/state/download-shelf.store';
import { DownloadShelfComponent } from './download-shelf.component';

@Component({
  selector: 'app-download-shelf-dialog-container',
  template: `
    @defer (when downloadShelfStore.visible()) {
      @if (downloadShelfStore.visible()) {
        <app-download-shelf-dialog />
      }
    }
  `,
  standalone: true,
  imports: [DownloadShelfComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadShelfContainerComponent {
  readonly downloadShelfStore = inject(DownloadShelfStore);
}

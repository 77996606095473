/* eslint-disable no-empty-character-class */
import { Injectable } from '@angular/core';
import { SchemaType, isTypeValid } from '@hestia-earth/schema';
import { HeSchemaService, schemaBaseUrl } from '@hestia-earth/ui-components';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, reduce } from 'rxjs/operators';
import { parse } from 'yaml';

import { enableNoVersion } from './schema.model';

export enum PropertyType {
  array = 'array',
  number = 'number',
  integer = 'integer',
  string = 'string',
  boolean = 'boolean',
  object = 'object',
  date = 'date',
  'date-time' = 'date-time',
  iri = 'iri',
  null = 'null'
}

export interface IProperty {
  name: string;
  type: PropertyType;
  doc?: string;
  enum?: string[];
  const?: any;
  pattern?: string;
  // default value if none is provided
  default?: any;
  unique?: boolean;
  required?: boolean;
  internal?: boolean;
  searchable?: boolean;
  deprecated?: boolean;
  geojson?: boolean;
  uniqueArrayItem?: string[];
  // augmented here
  schemaType?: SchemaType;
  typeName?: string;
  url?: string;
}

export interface ICustomValidationRules {
  const: any;
  required?: string[];
  enum?: string[];
  contains?: ICustomValidationRules;
  properties?: {
    [property: string]: ICustomValidationRules;
  };
  not?: ICustomValidationRules;
  items?: ICustomValidationRules;
  allOf?: ICustomValidationRules[];
  anyOf?: ICustomValidationRules[];
  oneOf?: ICustomValidationRules[];
}

export interface ICustomValidation {
  if: ICustomValidationRules;
  then: ICustomValidationRules;
}

export interface IValidations {
  allOf?: ICustomValidation[];
  anyOf?: ICustomValidation[];
  oneOf?: ICustomValidation[];
}

export interface IClass {
  name: string;
  type: 'Node' | 'Blank Node';
  examples?: string[];
  doc: string;
  properties: IProperty[];
  validation?: IValidations;
}

export type yamlContent = {
  [type in SchemaType]: IClass;
};

interface IContent {
  class: IClass;
}

const parseYaml = (value: string) => {
  const {
    class: { properties, ...data }
  } = parse(value) as IContent;
  return {
    ...data,
    properties: properties.map(formatProperty).map(extendProperty(data))
  };
};

export const referenceClass = 'reference-link';

const cleanType = (type: string) => type.replace(/Embed|Ref|List|\[|\]/g, '');

const propertySchemaType = (property: IProperty) => {
  const type = cleanType(property.type) as SchemaType;
  return isTypeValid({ type }) ? type : null;
};

const propertyTypeName = (property: IProperty) =>
  property.schemaType || property.type.replace(/(Embed\[|Ref\[)([a-zA-Z]*)(\])/g, '$2');

const defaultPropertyLink = 'https://www.w3.org/2019/wot/json-schema';

const typeToPropertyLink: {
  [type in PropertyType]: string;
} = {
  [PropertyType.array]: `${defaultPropertyLink}#ArraySchema`,
  [PropertyType.boolean]: `${defaultPropertyLink}#BooleanSchema`,
  [PropertyType.number]: `${defaultPropertyLink}#NumberSchema`,
  [PropertyType.integer]: `${defaultPropertyLink}#IntegerSchema`,
  [PropertyType.string]: `${defaultPropertyLink}#StringSchema`,
  [PropertyType.null]: `${defaultPropertyLink}#NullSchema`,
  [PropertyType.object]: `${defaultPropertyLink}#ObjectSchema`,
  [PropertyType.date]: 'https://json-schema.org/understanding-json-schema/reference/string.html#dates-and-times',
  [PropertyType['date-time']]:
    'https://json-schema.org/understanding-json-schema/reference/string.html#dates-and-times',
  [PropertyType.iri]: 'https://tools.ietf.org/html/rfc3987'
};

const propertyLink = (property: IProperty) => {
  // map type to link
  const schemaType = propertySchemaType(property);
  return property.geojson
    ? 'https://tools.ietf.org/html/rfc7946'
    : schemaType
      ? `/schema/${schemaType}`
      : property.type.startsWith(PropertyType.array)
        ? typeToPropertyLink[PropertyType.array]
        : typeToPropertyLink[property.type];
};

const formatCodeValue = (value: string) => `<code>${value}</code>`;

const joinValues = (values: string[]) => (values || []).filter(Boolean).map(formatCodeValue).join('<span>, </span>');

const formatListLine = (values: string[] | string[][]) =>
  values
    .flat()
    .map(r => `<li>${r}</li>`)
    .join('');

const formatList = (values: string[] | string[][]) =>
  `<ul class='is-list-style-disc is-pl-4'>${formatListLine(values)}</ul>`;

const paragraph = (value: string) => `<p class="is-mt-2">${value}</p>`;

const handleMdLink = (value: string) => {
  const text = value.match(/([])|\[(.*?)\]/gm)?.[0]?.replace(/\[|\]/g, '');
  const link = value.match(/\]\(.*?\)/gm)?.[0]?.replace(/\[|\]|\(|\)/g, '');
  const isReference = link.startsWith('#');
  return isReference
    ? `<a data-id="${link.replace('#', '')}" class="${referenceClass}" href="#" target="_blank">${text}</a>`
    : `<a href="${link}">${text}</a>`;
};

const parseMdLinks = (value: string) => value.replace(/([])|\[(.*?)\]\(.*?\)/gm, handleMdLink);

// TODO: match complex validations where we match properties values
const matchValidation = (property: IProperty, validations: IValidations) =>
  (Object.values(validations).flat() as ICustomValidation[]).filter(
    v =>
      [v.if?.required?.includes(property.name), v.if?.anyOf?.some(v => v.required?.includes(property.name))].some(
        Boolean
      ) && !v.if.properties
  );

/* eslint-disable complexity */
const formatValidationRule = (rule: ICustomValidationRules, path = '', negation = false) =>
  rule?.required?.length
    ? `${rule.required.map(formatCodeValue).join(', ')} must ${negation ? '<u>not</u>' : 'also'} be set.`
    : rule?.enum?.length
      ? `the ${formatCodeValue(path)} must have <u>${negation ? 'none' : 'one'}</u> of the following values: ${joinValues(
          rule.enum
        )}.`
      : rule.properties
        ? Object.entries(rule.properties).map(([key, value]) =>
            formatValidationRule(value, [path, key].filter(Boolean).join('.'))
          )
        : rule.items
          ? formatValidationRule(rule.items, path)
          : rule.not
            ? formatValidationRule(rule.not, path, true)
            : rule.anyOf
              ? `<u>${negation ? 'none' : 'one'}</u> of the following must be valid: ${formatList(
                  rule.anyOf.map(r => formatValidationRule(r))
                )}`
              : enableNoVersion
                ? JSON.stringify(rule)
                : '';
/* eslint-enable complexity */

const formatCustomValidation = (validations: ICustomValidation[]) => {
  const rules = validations.map(rule => formatValidationRule(rule.then));
  return rules?.length ? `<span>This field has additional validation rules when set:</span>${formatList(rules)}` : '';
};

/* eslint-disable complexity */
const formatDescription = (property: IProperty, data: Partial<IClass>) => {
  const enumText = property.enum ? paragraph(`<span>Possible values are: </span>${joinValues(property.enum)}`) : '';
  const defaultText = property.default
    ? paragraph(`<span>Defaults to: </span>${formatCodeValue(property.default)}`)
    : '';
  const constText = property.const
    ? paragraph(`<span>Possible values are: </span>${formatCodeValue(property.const.toString())}`)
    : '';
  const uniqueText = property.uniqueArrayItem?.length
    ? paragraph(`
    <span class="is-capitalized">${property.name}</span>
    <span class="is-pl-1">cannot be duplicated.</span>
    <span>The following fields determine whether a</span>
    <span class="is-pl-1">${property.schemaType} is unique:</span>
    ${joinValues(property.uniqueArrayItem)}
    `)
    : '';
  const validationText = data.validation
    ? paragraph(formatCustomValidation(matchValidation(property, data.validation)))
    : '';
  return property.doc
    ? `<p>${parseMdLinks(property.doc)}</p>${enumText}${defaultText}${constText}${uniqueText}${validationText}`
    : '';
};
/* eslint-enable complexity */

const formatProperty = (property: IProperty) => ({
  ...property,
  schemaType: propertySchemaType(property),
  typeName: propertyTypeName(property)
});

const extendProperty = (data: Partial<IClass>) => (property: IProperty) => ({
  ...property,
  doc: formatDescription(property, data),
  url: propertyLink(property)
});

@Injectable({
  providedIn: 'root'
})
export class SchemaService extends HeSchemaService {
  public loadYaml$(version: string) {
    return from(Object.values(SchemaType)).pipe(
      mergeMap(type =>
        this.http
          .get(`${schemaBaseUrl(version)}/yaml/${type}.yaml`, {
            responseType: 'text'
          })
          .pipe(
            map(schema => ({ type, schema: parseYaml(schema) })),
            catchError(() => of({ type, schema: { properties: [] } }))
          )
      ),
      reduce((prev, { type, schema }) => ({ ...prev, [type]: schema }), {} as yamlContent)
    );
  }
}

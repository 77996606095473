import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FooterVisibilityService {
  public isVisible = toSignal(this.router.events.pipe(map(() => !this.router.url.startsWith('/explorer'))));

  constructor(private router: Router) {}
}

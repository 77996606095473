import { AfterViewInit, Component, DestroyRef, inject, PLATFORM_ID, signal } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet
} from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { scrollTop, ToastComponent } from '@hestia-earth/ui-components';

import { loadScript } from './utils';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { GeneralNoticeBannerComponent } from './shared/general-notice-banner/general-notice-banner.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DownloadShelfContainerComponent } from './download-shelf-dialog/download-shelf-dialog-container/download-shelf-container.component';

const deferScripts = [
  // 'https://embed.small.chat/TNP4Z45KNG01CPUPKU7R.js', // disable for now as storing user IP addresses
  'zip/zip.min.js'
];

const isNavigationEnd = (event: Event) =>
  [event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError].some(
    Boolean
  );

const urlToPath = (url: string) => (url.includes('?') ? url.substring(0, url.indexOf('?')) : url);

const pageChanged = (newUrl: string, previousUrl = '') => !!previousUrl && urlToPath(previousUrl) !== urlToPath(newUrl);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    ToastComponent,
    NavbarComponent,
    FooterComponent,
    GeneralNoticeBannerComponent,
    DownloadShelfContainerComponent
  ]
})
export class AppComponent implements AfterViewInit {
  private document = inject(DOCUMENT);
  private platformId = inject(PLATFORM_ID);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private deviceService = inject(DeviceDetectorService);

  private events$ = this.router.events.pipe(takeUntilDestroyed(this.destroyRef));

  private previousUrl: string;
  protected loading = signal(false);

  constructor() {
    const os = this.deviceService.getDeviceInfo().os.toLowerCase();
    this.document.body.classList.add(`is-os-${os}`);
    this.events$.subscribe(event => this.onRouteEvent(event));
  }

  ngAfterViewInit() {
    return isPlatformBrowser(this.platformId) && Promise.all(deferScripts.map(loadScript));
  }

  private onRouteEvent(event: Event) {
    if (event instanceof NavigationStart) {
      this.loading.set(true);
    }

    if (isNavigationEnd(event) && 'url' in event) {
      this.loading.set(false);
      pageChanged(event.url, this.previousUrl) && scrollTop();
      this.previousUrl = event.url;
    }
  }
}

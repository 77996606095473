<div class="search-container">
  <div class="container is-py-2">
    <form (submit)="runSearch()">
      <div class="field has-addons">
        <div class="control dropdown-container is-hidden-mobile">
          <div class="dropdown is-small" #dropdown [class.is-active]="showSelectSearchType">
            <div class="dropdown-trigger">
              <button
                class="button is-small is-white is-rounded has-text-weight-normal w-100"
                type="button"
                aria-haspopup="true"
                aria-controls="selectedSearchType"
                (click)="showSelectSearchType = !showSelectSearchType"
                [disabled]="glossarySearch"
                [class.is-open]="showSelectSearchType">
                <span class="has-text-left w-100">{{ selectedSearchType }}</span>
                <span class="icon is-small">
                  <fa-icon icon="caret-down"></fa-icon>
                </span>
              </button>
            </div>
            <div class="dropdown-menu" id="selectedSearchType" role="menu">
              <div class="dropdown-content" (click)="showSelectSearchType = false">
                @if (!glossarySearch) {
                  <a
                    class="dropdown-item"
                    (click)="selectedSearchType = SearchType.aggregated"
                    [class.is-hidden]="selectedSearchType === SearchType.aggregated">
                    Aggregated Data
                  </a>
                  <a
                    class="dropdown-item"
                    (click)="selectedSearchType = SearchType.Cycle"
                    [class.is-hidden]="selectedSearchType === SearchType.Cycle">
                    Cycles
                  </a>
                  <a
                    class="dropdown-item"
                    (click)="selectedSearchType = SearchType.Source"
                    [class.is-hidden]="selectedSearchType === SearchType.Source">
                    Sources
                  </a>
                }
                @if (glossarySearch) {
                  <a class="dropdown-item" (click)="selectedSearchType = SearchType.Term">Terms</a>
                }
              </div>
            </div>
          </div>
        </div>
        <div class="control is-expanded has-icons-right">
          <input
            class="input is-small is-white is-rounded search-input"
            [placeholder]="placeholder"
            [(ngModel)]="searchInput"
            name="search"
            [ngbTypeahead]="suggest"
            [resultTemplate]="suggestion"
            [inputFormatter]="formatter"
            [focusFirst]="false"
            (selectItem)="selectSuggestion($event)" />
          <a class="icon is-small is-right" [class.is-hidden]="!searchInput" (click)="searchInput = ''">
            <fa-icon icon="times"></fa-icon>
          </a>
        </div>
        <div class="control">
          <button class="button is-white is-small is-rounded" type="submit">
            <fa-icon icon="search"></fa-icon>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="is-hidden-tablet is-p-3">
  <div class="columns is-mobile">
    <div class="column is-narrow">
      <span>By</span>
    </div>
    <div class="column">
      <div class="control">
        <label class="radio">
          <input
            type="radio"
            [(ngModel)]="selectedSearchType"
            name="selectedSearchType"
            [value]="SearchType.aggregated"
            [checked]="selectedSearchType === SearchType.aggregated" />
          <span class="pl-2">Aggregated Data</span>
        </label>
      </div>
      <div class="control">
        <label class="radio">
          <input
            type="radio"
            [(ngModel)]="selectedSearchType"
            name="selectedSearchType"
            [value]="SearchType.Cycle"
            [checked]="selectedSearchType === SearchType.Cycle" />
          <span class="pl-2">Cycles</span>
        </label>
      </div>
      <div class="control">
        <label class="radio">
          <input
            type="radio"
            [(ngModel)]="selectedSearchType"
            name="selectedSearchType"
            [value]="SearchType.Source"
            [checked]="selectedSearchType === SearchType.Source" />
          <span class="pl-2">Sources</span>
        </label>
      </div>
    </div>
  </div>
</div>

<div
  class="search-keywords columns is-mobile is-multiline is-centered is-variable is-2 is-mt-0 is-size-7"
  [class.is-invisible]="!searchKeywords[selectedSearchType].length">
  <div class="column is-narrow"><b class="has-text-secondary">Try</b></div>
  <div class="column is-narrow">
    <fa-icon icon="long-arrow-alt-right"></fa-icon>
  </div>
  @for (keyword of searchKeywords[selectedSearchType]; track keyword; let i = $index) {
    <div class="column is-narrow" [class.is-hidden-mobile]="i > 3">
      <a class="is-keyword" (click)="selectKeyword(keyword)">
        <b>{{ keyword }}</b>
      </a>
    </div>
  }
</div>

<ng-template #suggestion let-r="result" let-t="term">
  <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>

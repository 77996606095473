import { Component, OnInit, OnDestroy, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { User } from '@hestia-earth/api';
import { baseUrl, gitHome } from '@hestia-earth/ui-components';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AsyncPipe, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';

import { environment } from '../../../environments/environment';
import { NavbarService } from '../navbar.service';
import { UsersService } from '../../users/users.service';
import { AdminNavigationService } from '../../admin-navigation.service';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { NavbarDropdownDirective } from '../navbar-dropdown.directive';

const fixedTopBodyClass = 'has-spaced-navbar-fixed-top';

const initials = ({ firstName, lastName }: Partial<User> = {}) =>
  [firstName[0], lastName?.[0] || '.'].filter(Boolean).join('');

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [
    FaIconComponent,
    NgOptimizedImage,
    AsyncPipe,
    NgTemplateOutlet,
    RouterLink,
    SearchBarComponent,
    NavbarDropdownDirective
  ]
})
export class NavbarComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  private adminNavigationService = inject(AdminNavigationService);
  protected usersService = inject(UsersService);
  protected navbarService = inject(NavbarService);

  private events$ = this.router.events.pipe(takeUntilDestroyed(this.destroyRef));

  protected isMobile = window.innerWidth < 768;

  protected baseUrl = baseUrl();
  protected apiBaseUrl = environment.api.baseUrl;
  protected gitHome = gitHome;
  protected menuActive = false;
  protected userMenuActive = false;
  protected searchMenuActive = false;

  protected adminDashboardEnabled = this.adminNavigationService.enabled;
  protected adminDashboardLink = this.adminNavigationService.defaultLink;

  constructor() {
    this.events$.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => this.resetMenu());
  }

  ngOnInit() {
    return !this.isMobile && document.body.classList.add(fixedTopBodyClass);
  }

  ngOnDestroy() {
    return !this.isMobile && document.body.classList.remove(fixedTopBodyClass);
  }

  protected resetMenu() {
    this.menuActive = false;
    this.userMenuActive = false;
    this.searchMenuActive = false;
  }

  protected isCurrentUrl(url: string) {
    return this.router.url === url;
  }

  protected startsWithUrl(url: string) {
    return this.router.url.startsWith(url);
  }

  protected get userInitials$() {
    return this.usersService.user$.pipe(map(user => (user ? initials(user) : (this.userMenuActive = false))));
  }
}

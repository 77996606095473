import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HeToastService } from '@hestia-earth/ui-components';

@Injectable()
export class ReadOnlyInterceptor implements HttpInterceptor {
  constructor(private toastService: HeToastService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        error.status === 405 && this.showMessage();
        return throwError(error);
      })
    );
  }

  private showMessage() {
    return this.toastService.error(
      `
      The HESTIA Platform is currently in read-only mode.
      You can continue to browse the database but will not be able to access your uploads.
    `.trim()
    );
  }
}

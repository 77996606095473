import { Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { NodeType } from '@hestia-earth/schema';
import {
  matchType,
  searchResult,
  filterParams,
  matchAggregatedQuery,
  suggestMatchQuery,
  HeSearchService,
  matchQuery
} from '@hestia-earth/ui-components';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { NgbHighlight, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

enum SearchType {
  aggregated = 'Aggregated Data',
  Cycle = 'Cycles',
  Source = 'Sources',
  Term = 'Terms'
}

const searchKeywordsCycle = ['Wheat', 'Potato', 'Tilapia', 'Beef', 'Chicken', 'Australia', 'Japan', 'United Kingdom'];

const searchKeywords: {
  [type in SearchType]: string[];
} = {
  [SearchType.Cycle]: searchKeywordsCycle,
  [SearchType.Source]: ['Henriksson et al (2017)', 'Food Security Policy Project (2020)', 'Engström et al (2011)'],
  [SearchType.Term]: [],
  [SearchType.aggregated]: searchKeywordsCycle
};

// default query parameters
const searchKeywordQueryPrams = Object.freeze({
  page: 1,
  sortOrder: 'desc',
  sortBy: ''
});

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  standalone: true,
  imports: [FaIconComponent, FormsModule, NgbTypeahead, NgbHighlight]
})
export class SearchBarComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];

  @ViewChild('dropdown')
  private dropdown: ElementRef;

  public glossarySearch = false;

  public searchInput;
  public showSelectSearchType = false;
  public SearchType = SearchType;
  public selectedSearchType = SearchType.aggregated;
  public searchKeywords = searchKeywords;
  public suggest = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term => this.suggestTerm(term))
    );
  public formatter = ({ name }: searchResult) => name;

  constructor(
    private router: Router,
    private searchService: HeSearchService
  ) {
    this.subscriptions.push(router.events.subscribe((event: Event) => this.onRouteEvent(event)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // fix for (clickOutside) not working here for some reason
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.dropdown.nativeElement.contains(event.target)) {
      this.showSelectSearchType = false;
    }
  }

  private onRouteEvent(event: Event) {
    if (event instanceof NavigationEnd) {
      this.glossarySearch = !!this.router.url.match(/^\/term\//);
      this.selectedSearchType = this.glossarySearch ? SearchType.Term : this.selectedSearchType;
    }
  }

  private get searchUrl() {
    return this.glossarySearch ? 'glossary' : 'search';
  }

  private get query() {
    return this.searchInput ? (typeof this.searchInput === 'string' ? this.searchInput : this.searchInput.name) : null;
  }

  private get tab() {
    return this.selectedSearchType === SearchType.Source ? NodeType.Source : NodeType.Cycle;
  }

  private suggestTerm(term: string) {
    const searchType = this.selectedSearchType === SearchType.Source ? NodeType.Source : NodeType.Cycle;
    const query = {
      bool: {
        must: [
          matchType(searchType),
          ...(this.selectedSearchType === SearchType.aggregated
            ? [matchAggregatedQuery, matchQuery('aggregatedDataValidated', true)]
            : [])
        ],
        must_not: [...(this.selectedSearchType !== SearchType.aggregated ? [matchAggregatedQuery] : [])],
        should: suggestMatchQuery(term),
        minimum_should_match: 1
      }
    };
    return this.glossarySearch
      ? this.searchService.suggest$(term, NodeType.Term)
      : this.searchService.suggest$(term, null, null, query);
  }

  public selectSuggestion({ item }) {
    setTimeout(() => this.runSearch(item['@type']));
  }

  private executeSearch(queryParams: any) {
    return this.router.navigate(['/', this.searchUrl], {
      ...(Object.keys(queryParams).length ? { queryParams } : {})
    });
  }

  public get placeholder() {
    return this.glossarySearch ? 'Search glossary of terms' : 'Search for crops, animal products, regions...';
  }

  public runSearch(tab?: NodeType) {
    const queryParams = filterParams({
      query: this.query || '',
      tab: tab || this.tab,
      page: 1,
      sortOrder: 'desc',
      aggregated: this.selectedSearchType === SearchType.aggregated ? true : undefined
    });
    return this.executeSearch(queryParams);
  }

  public selectKeyword(query: string) {
    const queryParams = filterParams({
      ...searchKeywordQueryPrams,
      query,
      tab: this.tab,
      aggregated: this.selectedSearchType === SearchType.aggregated ? true : undefined
    });
    return this.executeSearch(queryParams);
  }
}

import { Directive, ElementRef, HostListener, inject } from '@angular/core';

const hideClass = 'is-hidden';

const blur = () => {
  try {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  } catch (_err) {
    // ignore error
  }
};

@Directive({
  selector: '[appNavbarDropdown]',
  standalone: true
})
export class NavbarDropdownDirective {
  private ref = inject(ElementRef);

  @HostListener('click')
  onClick() {
    const enabled = window.innerWidth < 1024;
    blur();
    return !enabled || this.toggleClass();
  }

  private toggleClass() {
    const dropdown: HTMLDivElement = this.ref.nativeElement.querySelector('.navbar-dropdown');
    return dropdown.classList.contains(hideClass)
      ? dropdown.classList.remove(hideClass)
      : dropdown.classList.add(hideClass);
  }
}

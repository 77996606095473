import { InjectionToken } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

export const CHARTS_LOADED = new InjectionToken<Observable<boolean>>('Google Charts Loaded');

const loadGoogleCharts = (apiKey: string, packages = ['geochart']) => {
  return new Promise<void>(resolve => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://www.gstatic.com/charts/loader.js';
    script.async = true;
    script.addEventListener('load', () => {
      google.charts.load('current', { packages, mapsApiKey: apiKey });
      google.charts.setOnLoadCallback(() => resolve());
    });
    document.getElementsByTagName('head')[0].appendChild(script);
  });
};

export const loadCharts =
  (apiKey: string, packages = ['geochart']) =>
  () =>
    from(loadGoogleCharts(apiKey, packages)).pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      map(() => true)
    );

import { InjectionToken } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { LOCAL_STORAGE_DOWNLOAD_TOKEN } from '../../utils/constants';

export const LOCAL_DOWNLOAD_TOKEN = new InjectionToken('Local Storage Download Token', {
  providedIn: 'root',
  factory: () => {
    const token = localStorage.getItem(LOCAL_STORAGE_DOWNLOAD_TOKEN) ?? `${uuidv4()}-${new Date().getTime()}`;
    localStorage.setItem(LOCAL_STORAGE_DOWNLOAD_TOKEN, token);
    return token;
  }
});

import { inject, Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { UsersService } from '../../../users/users.service';
import { DownloadApiService } from '../services/download-api.service';
import { DOWNLOAD_ENABLED } from '../../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class DownloadTokenStore extends ComponentStore<{
  downloadToken: string;
}> {
  private readonly usersService = inject(UsersService);
  private readonly downloadApiService = inject(DownloadApiService);

  constructor() {
    super({ downloadToken: '' });

    DOWNLOAD_ENABLED &&
      this._fetchDownloadToken(
        this.usersService.user$.pipe(
          debounceTime(100),
          map(user => user?.email ?? ''),
          distinctUntilChanged()
        )
      );
  }

  readonly downloadToken$ = this.select(state => state.downloadToken);

  private readonly _fetchDownloadToken = this.effect((user$: Observable<string>) =>
    user$.pipe(
      tap(() => this.patchState({ downloadToken: '' })),
      switchMap(_ =>
        this.downloadApiService.getDownloadToken().pipe(
          tapResponse({
            next: ({ token }) => this.patchState({ downloadToken: token }),
            error: console.error
          })
        )
      )
    )
  );
}

import { Injectable, inject } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';

import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  private router = inject(Router);
  private usersService = inject(UsersService);

  canActivate(_r, state: RouterStateSnapshot) {
    return this.usersService.user$.pipe(
      map(user => {
        const loggedIn = !!user;
        this.usersService.redirectUrl = loggedIn ? null : state.url;
        return loggedIn || this.router.parseUrl('/users/signin');
      })
    );
  }
}
